import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";

//
import logo from "../../images/logo-2.png";
import login from "../../images/bg-login2.png";
import loginbg from "../../images/bg-login.jpg";

function Login(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("demo@example.com");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("123456");
  const dispatch = useDispatch();
  const loginButtonRef = useRef(null); // Création de la référence au bouton

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log(loginButtonRef);
      loginButtonRef.current.click(); // Simule un clic sur le bouton de connexion après 200ms
    }, 2000);

    return () => clearTimeout(timer); // Nettoyage en cas de démontage du composant
  }, []);

  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(loadingToggleAction(true));
    dispatch(loginAction(email, password, navigate));
  }

  return (
    <div
      className="login-main-page"
      style={{ backgroundImage: "url(" + loginbg + ")" }}
    >
      <div className="login-wrapper">
        <div
          className="login-aside-left"
          style={{ backgroundImage: "url(" + login + ")" }}
        >
          {/* <Link to="/" className="login-logo">
            <img src={logo} alt="" />
          </Link> */}
          <div className="login-description">
            <h2 className="mb-2">Checkez vos stats !</h2>
            <p className="fs-12">
              Vos statistiques en direct pour rester informé de vos
              performances.
            </p>
            <ul className="social-icons mt-4">
              <li>
                <a href="https://www.linkedin.com/company/34701228">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
            </ul>
            <div className="mt-5">
              <a href="https://wecodx.com/privacy-policy/" className=" me-4">
                Politique de confidentialité
              </a>
              <a href="mailto:support@wecodx.com" className=" me-4">
                Contact
              </a>
              <p className=" me-4">
                © 2023 par
                <a href="https://www.wecodx.com" > Wecodx</a>
              </p>
              <p className="">
                <a href="https://wecodx.com/notice-legale/">Mentions légales</a>
              </p>
            </div>
          </div>
        </div>

        <div className="login-aside-right">
          <div className="row m-0 justify-content-center h-100 align-items-center">
            <div className="col-xl-7 col-xxl-7">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form-1">
                      <div className="mb-4">
                        <h3 className="text-primary mb-1">Bienvenue</h3>
                        <p className="">Votre plateforme de statistiques</p>
                      </div>
                      {/* Display error and success messages */}
                      {props.errorMessage && (
                        <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                          {props.errorMessage}
                        </div>
                      )}
                      {props.successMessage && (
                        <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                          {props.successMessage}
                        </div>
                      )}
                      {/* No need for login button */}
                      <div className="text-center">
                        <button
                          ref={loginButtonRef} 
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={onLogin}
                        >
                          Se connecter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);

const config = {
	NAME_OF_CUSTOMER: 'Customer',
	TIME_OF_RELOAD: 10,
	VISUM_TOKEN: 'fufhsHTfD4536779HhreyuervdfHBR7NI9642YV79BTFY9U094BVYSTRUBEdsfgdgrthjdfn',
	VISUM_ID: 1,
	VISUM_HOST: 'https://app.visum-company.com',
    VISTCONNECT_HOST: 'https://app.vistconnect.com',
	VISTCONNECT_TOKEN: 'fdsDGSUBydtb457769vhfdghbFDGH',
	DIABHEALTH_HOST: 'https://app.diabhealth.be',
	DIABHEALTH_TOKEN: 'dfsgsvYRTYV8764Z245VUrufgdvuVTY657435vr',
	BURO_HOST: 'https://webapp.wecodx.com',
	BURO_ID: '1'
};

export default config;
import jsonp from "jsonp";
import config from "../../config";

const makeJsonpRequest = (url) => {
    return new Promise((resolve, reject) => {
        jsonp( url, { timeout: 1000, name: "__jp26" }, (error, data) => {
            console.log(data);
            if (error) {
                console.error("Erreur lors de la récupération des données de visum:", error);
                reject(error);
            } else {
                resolve(data);
            }
        });
    });
};

export const getCustomers = () => {
    const apiUrl = `${config.VISUM_HOST}/api/get-number/clients/${config.VISUM_ID}/${config.VISUM_TOKEN}`;
    return makeJsonpRequest(apiUrl).then(data => data.numberOfClients);
};

export const getInvoices = () => {
    const apiUrl = `${config.VISUM_HOST}/api/get-number/invoices/${config.VISUM_ID}/${config.VISUM_TOKEN}`;
    return makeJsonpRequest(apiUrl).then(data => data.numberOfInvoices);
};

export const getProjects = () => {
    const apiUrl = `${config.VISUM_HOST}/api/get-number/projects/${config.VISUM_ID}/${config.VISUM_TOKEN}`;
    return makeJsonpRequest(apiUrl).then(data => data.numberOfProjects);
};

export const getCollaborators = () => {
    const apiUrl = `${config.VISUM_HOST}/api/get-number/collaborators/${config.VISUM_ID}/${config.VISUM_TOKEN}`;
    return makeJsonpRequest(apiUrl).then(data => data.numberOfCollaborators);
};

export const getStatsVisum = () => {
	const apiUrl = `${config.VISUM_HOST}/api/get-stats/`;
	return makeJsonpRequest(apiUrl).then(data => data);
};

export const getStatsVistconnect = () => {
	const apiUrl = `${config.VISTCONNECT_HOST}/api/get-stats/${config.VISTCONNECT_TOKEN}`;
	return makeJsonpRequest(apiUrl).then(data => data);
}

export const getStatsDiabhealth = () => {
    const apiUrl = `${config.DIABHEALTH_HOST}/api/get-stats/${config.DIABHEALTH_TOKEN}`;
    return makeJsonpRequest(apiUrl).then(data => data);
}

export const getStatsBuro = () => {
    const apiUrl = `${config.BURO_HOST}/stats/coffees/${config.BURO_ID}`;
    return makeJsonpRequest(apiUrl).then(data => data);
}


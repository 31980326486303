import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import pMinDelay from "p-min-delay";
import loadable from "@loadable/component";
import config from "../../config";
import { getCustomers, getInvoices, getProjects, getCollaborators, getStatsVisum, getStatsVistconnect, getStatsDiabhealth, getStatsBuro } from "../api/visum";

const ApexBar2 = loadable(() =>
  pMinDelay(import("../components/charts/apexcharts/Bar2"), 1000)
);
const ApexLine = loadable(() =>
  pMinDelay(import("../components/charts/apexcharts/Line5"), 1000)
);

const Stats = () => {
  const [countdown, setCountdown] = useState(config.TIME_OF_RELOAD);
  const [totalCustomer, setTotalCustomer] = useState(0);
  const [totalInvoice, setTotalInvoice] = useState(0);
  const [totalProject, setTotalProject] = useState(0);
  const [totalCollaborators, setTotalCollaborators] = useState(0);
  const [statsVisum, setStatsVisum] = useState({
      numberOfUsers: 0,
      numberOfInvoices: 0,
      numberOfCompanies: 0,
      pagesSeenToday: 0
  });
  const [statsVistconnect, setStatsVistconnect] = useState({
      numberOfCards: 0,
      scansToday: 0,
      scansThisWeek: 0,
      scansThisMonth: 0
  });
  const [statsDiabhealth, setStatsDiabhealth] = useState({
      numberOfUsers: 0,
      scansThisMonth: 0,
      numberOfMeals: 0,
      scansBoughtThisYear: 0
  });
  const [statsBuro, setStatsBuro] = useState({
      coffees: 0,
  });
  const [statsFun, setStatsFun] = useState({
      linesOfCode: 0,
      songs: 0,
      slackMessages: 0
  })

  let reloadDataBoolean = false;

  // Fonction pour charger les statistiques de Visum
  const loadVisumStats = async () => {
      const customerData = await getCustomers();
      const invoiceData = await getInvoices();
      const projectData = await getProjects();
      const collaboratorData = await getCollaborators();
      const statsVisum = await getStatsVisum();
      const statsVistconnect = await getStatsVistconnect();
      const statsDiabhealth = await getStatsDiabhealth();
      const statsBuro = await getStatsBuro();
      Promise.all([
        customerData,
        invoiceData,
        projectData,
        collaboratorData,
        statsVisum,
        statsVistconnect]).then(([
          customerData,
          invoiceData,
          projectData,
          collaboratorData,
          statsVisum,
          statsVistconnect]) => {
        setTotalCustomer(customerData);
        setTotalInvoice(invoiceData);
        setTotalProject(projectData);
        setTotalCollaborators(collaboratorData);
        setStatsVisum(statsVisum);
        setStatsVistconnect(statsVistconnect);
        setStatsDiabhealth(statsDiabhealth);
        setStatsBuro(statsBuro);
        setStatsFun(workOutStatsFun());
      }).catch(error => {
        console.log(error);
      });
      
      // Traitez les statistiques de Visum selon vos besoins
   
  };

  function workOutStatsFun() {
    const currentDate = new Date();
    const referenceDate = new Date("2019-05-28");
    const timeDiff = Math.abs(currentDate.getTime() - referenceDate.getTime());
    // Convertir la différence de temps en minutes au lieu de jours
    const minutesPassed = Math.ceil(timeDiff / (1000 * 60)); 
  
    // Ajuster les multiplicateurs pour refléter l'accumulation par minute
    // Vous devrez ajuster ces valeurs en fonction de la vitesse à laquelle vous souhaitez que les statistiques augmentent par minute
    const currentCafes = (16 / 1440) * minutesPassed; // Exemple: 16 cafés par jour divisé par 1440 minutes par jour
    const currentLinesOfCode = (532 / 1440) * minutesPassed; // Idem pour les lignes de code
    const currentSongs = (46 / 1440) * minutesPassed; // Et les chansons
  
    return {
      linesOfCode: currentLinesOfCode.toLocaleString('fr-FR', {maximumFractionDigits: 0}),
      songs: currentSongs.toLocaleString('fr-FR', {maximumFractionDigits: 0}),
      slackMessages: (0).toLocaleString('fr-FR')
    }
  }
  



  // Effectue le chargement initial des statistiques de Visum et configure le minuteur
  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (config.TIME_OF_RELOAD === prevCountdown) {
          reloadDataBoolean = false;
        }
        if (prevCountdown === 0 && !reloadDataBoolean) {
          reloadDataBoolean = true;
          reloadData();
        }
        if (prevCountdown === 0) {
          return config.TIME_OF_RELOAD;
        } else {
          return prevCountdown - 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Fonction pour recharger les données du dashboard
  const reloadData = () => {
    loadVisumStats();
    console.log("reloadData called");
  };

  // Formatage du compte à rebours
  const formatCountdown = () => {
    const minutes = Math.floor(countdown / config.TIME_OF_RELOAD);
    const seconds = countdown % config.TIME_OF_RELOAD;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div className="authincation">
      <div className="container-fluid vw-100 d-flex flex-column justify-content-around  align-items-center">
        <div className="row justify-content-center  align-items-center w-100">
          <p className="fs-12">
            Rafraîchissement des données dans {formatCountdown()}
          </p>
        </div>
        {/* 1ere ligne */}
        <div className="row justify-content-center  align-items-center w-100">
          <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
            <div className="widget-stat card bg-danger">
              <div className="card-body  p-4">
                <div className="media">
                  <span className="me-3">
                    <i className="flaticon-381-user-9"></i>
                  </span>
                  <div className="media-body text-white text-end">
                    <p className="mb-1">Collaborateurs</p>
                    <h3 className="text-white">{totalCollaborators}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
            <div className="widget-stat card bg-secondary">
              <div className="card-body  p-4">
                <div className="media">
                  <span className="me-3">
                    <i className="la la-tools"></i>
                  </span>
                  <div className="media-body text-white text-end">
                    <p className="mb-1">Projects</p>
                    <h3 className="text-white">{totalProject}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
            <div className="widget-stat card bg-success">
              <div className="card-body p-4">
                <div className="media">
                  <span className="me-3">
                    <i className="flaticon-381-folder-17"></i>
                  </span>
                  <div className="media-body text-white text-end">
                    <p className="mb-1">Factures</p>
                    <h3 className="text-white">{totalInvoice}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
            <div className="widget-stat card bg-info">
              <div className="card-body p-4">
                <div className="media">
                  <span className="me-3">
                    <i className="flaticon-381-user-7"></i>
                  </span>
                  <div className="media-body text-white text-end">
                    <p className="mb-1">Clients</p>
                    <h3 className="text-white">{totalCustomer}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 2eme ligne */}
        <div className="row justify-content-center  align-items-center w-100">
          <Col xl={3}>
            <div class="card project-boxed">
              <div class="img-bx"></div>
              <div class="card-header align-items-start">
                <div>
                  <h6 class="fs-18 font-w500 mb-3">
                    <a class="text-black user-name" href="/task">
                    Visum
                    </a>
                  </h6>
                </div>
              </div>
              <div class="card-body p-0 pb-3">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <span class="mb-0 title">Utilisateurs</span> :
                    <span class="text-black ms-2">{statsVisum.numberOfUsers}</span>
                  </li>
                  <li class="list-group-item">
                    <span class="mb-0 title">Sociétés</span> :
                    <span class="text-black ms-2">{statsVisum.numberOfCompanies}</span>
                  </li>
                  <li class="list-group-item">
                    <span class="mb-0 title">Pages vues aujourd'hui</span> :
                    <span class="text-black desc-text ms-2">{statsVisum.pagesSeenToday}</span>
                  </li>
                  <li class="list-group-item">
                    <span class="mb-0 title">Nombre total de factures</span> :
                    <span class="text-black desc-text ms-2">{statsVisum.numberOfInvoices}</span>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xl={3}>
            <div class="card project-boxed">
              <div class="img-bx"></div>
              <div class="card-header align-items-start">
                <div>
                  <h6 class="fs-18 font-w500 mb-3">
                    <a class="text-black user-name" href="/task">
                      VistConnect
                    </a>
                  </h6>
                </div>
              </div>
              <div class="card-body p-0 pb-3">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <span class="mb-0 title">Cartes</span> :
                    <span class="text-black ms-2">{statsVistconnect.numberOfCards}</span>
                  </li>
                  <li class="list-group-item">
                    <span class="mb-0 title">Scans aujourd'hui</span> :
                    <span class="text-black ms-2">{statsVistconnect.scansToday}</span>
                  </li>
                  <li class="list-group-item">
                    <span class="mb-0 title">Scans cette semaine</span> :
                    <span class="text-black desc-text ms-2">{statsVistconnect.scansThisWeek}</span>
                  </li>
                  <li class="list-group-item">
                    <span class="mb-0 title">Scans ce mois-ci</span> :
                    <span class="text-black desc-text ms-2">{statsVistconnect.scansThisMonth}</span>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xl={3}>
            <div class="card project-boxed">
              <div class="img-bx"></div>
              <div class="card-header align-items-start">
                <div>
                  <h6 class="fs-18 font-w500 mb-3">
                    <a class="text-black user-name" href="/task">
                    DiabHealth
                    </a>
                  </h6>
                </div>
              </div>
              <div class="card-body p-0 pb-3">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <span class="mb-0 title">Utilisateurs</span> :
                    <span class="text-black ms-2">{statsDiabhealth.numberOfUsers}</span>
                  </li>
                  <li class="list-group-item">
                    <span class="mb-0 title">Scans ce mois-ci</span> :
                    <span class="text-black ms-2">{statsDiabhealth.scansThisMonth}</span>
                  </li>
                  <li class="list-group-item">
                    <span class="mb-0 title">Repas ajoutés</span> :
                    <span class="text-black desc-text ms-2">{statsDiabhealth.numberOfMeals}</span>
                  </li>
                  <li class="list-group-item">
                    <span class="mb-0 title">Abonnements en cours</span> :
                    <span class="text-black desc-text ms-2">{statsDiabhealth.scansBoughtThisYear}</span>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xl={3}>
            <div class="card project-boxed">
              <div class="img-bx"></div>
              <div class="card-header align-items-start">
                <div>
                  <h6 class="fs-18 font-w500 mb-3">
                    <a class="text-black user-name" href="/task">
                      Fun
                    </a>
                  </h6>
                </div>
              </div>
              <div class="card-body p-0 pb-3">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <span class="mb-0 title">Cafés</span> :
                    <span class="text-black ms-2">{statsBuro.coffees}</span>
                  </li>
                  <li class="list-group-item">
                    <span class="mb-0 title">Musique</span> :
                    <span class="text-black ms-2">{statsFun.songs}</span>
                  </li>
                  <li class="list-group-item">
                    <span class="mb-0 title">Lignes de code</span> :
                    <span class="text-black desc-text ms-2">{statsFun.linesOfCode}</span>
                  </li>
                  <li class="list-group-item">
                    <span class="mb-0 title">Messages Slack</span> :
                    <span class="text-black desc-text ms-2">{statsFun.slackMessages}</span>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </div>

        {/* 3eme ligne */}
        {/* <div className="row justify-content-center  align-items-center w-100">
          <Col xl={6}>
            <Card>
              <Card.Header>
                <Card.Title>Bar Chart</Card.Title>
              </Card.Header>
              <Card.Body>
                <ApexBar2 />
              </Card.Body>
            </Card>
          </Col>
          <Col xl={6}>
            <Card>
              <Card.Header>
                <Card.Title>Bar Chart</Card.Title>
              </Card.Header>
              <Card.Body>
                <ApexLine />
              </Card.Body>
            </Card>
          </Col>
        </div> */}
      </div>
    </div>
  );
};

export default Stats;
